<template>
    <div>
        <!--  课程目录 -->
        <page-list ref="packageInfo"
                   :package-list="packageList"
                   :search-to-props="searchToProps"
                   @search="search"
                   @clickItem="clickItem"
        >
            <!--单个学习包-->
            <template v-slot="scope">
                <package-card :item="scope.item"/>
            </template>
        </page-list>
    </div>
</template>

<script>
import PageList from "../components/pageList";
import PackageCard from "../components/packageCard";

export default {
    name: "index",
    components: {PackageCard, PageList},
    data() {
        return {
            packageList: [],
            // 搜索字段映射
            searchToProps: {
                package_type: {
                    label: '学习包类型',
                    isHidden: true,
                    type: 'eq',
                    value: 2
                },
                package_class: {
                    // -1：全部，0：，
                    label: '按 类 别',
                    type: 'selectOption',
                    value: -1,
                    options: [
                        {label: '全部', value: -1},
                        {label: '法学名家', value: 0},
                        {label: '本科课程', value: 1},
                        {label: '论文指导', value: 2},
                        {label: '简历优化', value: 3}
                    ]
                },
                name: {
                    label: '按 名 称',
                    type: 'input'
                }
            }
        }
    },
    methods: {
        // 搜索数据
        search(data) {
            // 向后端发送请求数据
            this.$api.post('/package/index/getPackageList', data).then(res => {
                this.packageList = res.data.records
                this.$refs.packageInfo.updateTotal(res.data.total)
            }).catch(err => {
                console.log('专栏数据获取失败', err)
            })
        },
        // 点击数据
        clickItem(item) {
            // 如果没有登录过，之前去登陆
            if (!this.$store.state.token) {
                this.$router.push("/login")
                return
            }
            // 登录了
            this.$router.push('/package/detail/' + item.id)
        }
    }
}
</script>

<style scoped lang="scss">

</style>